<template>
  <div
    id="wrapper"
    :class="{ 'd-flex flex-column hero': isSearchRequired }"
    :style="{
      'background-image': isSearchRequired ? getBannerURL : null,
    }"
  >
    <div class="loader" v-if="loading"></div>
    <div v-show="!loading">
      <header-component></header-component>
      <landing-page v-if="isSearchRequired"></landing-page>

      <!-- <footer class="footer" v-if="isSearchRequired">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <a href="#" class="footer__link" title="Contact Sales">
                Contact Sales
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
            <div class="col-lg-4">
              <a href="#" class="footer__link" title="Landlord Portal">
                Landlord Portal
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
            <div class="col-lg-4">
              <a href="#" class="footer__link" title="Broker Partner Program">
                Broker Partner Program
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
      </footer> -->
      <!-- <div class="modal__overlay" v-if="isSearchRequired" @click="removeClasss"></div> -->
      <router-view></router-view>
      <footer-component></footer-component>

    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/containers/client/Header";
import FooterComponent from "@/containers/client/Footer";
import LandingPage from "./LandingPage";
import { verifyUser } from "@/api/user";
import { setToken } from "@/utils/auth";
import { mapMutations } from "vuex";
export default {
  name: "Dashboard",
  components: {
    HeaderComponent,
    LandingPage,
    FooterComponent
  },
  mounted() {

    if (this.$route.query.token) {
      this.loading = true;
      verifyUser({ token: this.$route.query.token })
        .then((res) => {
          this.loading = false;
          const data = res.data.data;
          setToken(data.token);
          this["SET_TOKEN"](data.token);
          this["SET_NAME"](data.user.email);
          this["SET_ROLE"](data.user.role);
          this["SET_USER"](data.user);
        })
        .catch((err) => {
          this.loading = false;
          this.$notify({
            group: "notify",
            type: "error",
            text: err.response.data.msg,
          });
        });
    }
  },
  computed: {
    isSearchRequired() {
      return this.$route.path == "/";
    },
    getBannerURL() {
      return `url('${this.$store.state.common.publicPath}img/hero-banner.jpg')`;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapMutations("user", ["SET_TOKEN", "SET_NAME", "SET_ROLE", "SET_USER"]),
    removeClasss() {
      var body = document.body;
      body.classList.remove("is-modalOpen");
    },
  },
};
</script>
<style lang="scss">
// Import Main styles for this application
@import "../../assets/scss/client";
</style>
